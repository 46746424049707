import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
// import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideToastr } from 'ngx-toastr';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { sessionTokenInterceptor } from './core/interceptors/session-token.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideToastr({ preventDuplicates: true, progressBar: true }),
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptors([sessionTokenInterceptor])),
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'es-PE',
    },
  ],
};
