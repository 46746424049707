import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { SessionStorageService } from '@core/services/common/session-storage.service';
import { DataSession } from '../enums/session/data.enum';
import { ResAuthLoginDataCredencial } from '@core/models/login/auth.model';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '@pages/auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';

export const sessionTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const sessionStorageSvc = inject(SessionStorageService);
  const authSvc = inject(AuthService);
  const toastrSvc = inject(ToastrService);
  const tokenData = sessionStorageSvc.getItemObj<ResAuthLoginDataCredencial>(DataSession.TOKEN);
  let refresh = false;
  if (!tokenData) {
    return next(req);
  }
  const cloneReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${tokenData.access_token}`,
    },
  });

  return next(cloneReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 && !refresh) {
        refresh = true;
        authSvc.logout();
        toastrSvc.info("Su sesión ha expirado");
      }
      refresh = false;
      return throwError(() => error);
    })
  );
};
