import { Component, inject } from '@angular/core';
import { LoadingService } from '@shared/services/loading.service';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.css',
})
export class LoadingComponent {
  private readonly loadingSvc = inject(LoadingService);

  isLoading = this.loadingSvc.isLoading;
}
