import { Routes } from '@angular/router';
import { checkLogueadoGuard } from '@core/guards/check-logueado.guard';
import { sessionGuard } from '@core/guards/session.guard';
import { RolUsuario } from './core/enums/auths/rol.enum';
import { hasRolGuard } from '@core/guards/has-rol.guard';

export const routes: Routes = [
  {
    path: 'login',
    title: 'Sesión',
    loadComponent: () => import('./pages/auth/login/login.component').then((c) => c.LoginComponent),
    canActivate: [sessionGuard],
  },
  {
    path: 'nuevo-registro',
    title: 'Nuevo registro',
    loadComponent: () => import('./pages/auth/nuevo-registro/nuevo-registro.component').then((c) => c.NuevoRegistroComponent),
    canActivate: [sessionGuard],
  },
  {
    path: 'recuperar-password',
    title: 'Recuperar contraseña',
    loadComponent: () => import('./pages/auth/recuperar-contrasena/recuperar-contrasena.component').then((c) => c.RecuperarContrasenaComponent),
    canActivate: [sessionGuard],
  },
  {
    path: 'solicitud-baja-usuario',
    title: 'Solicitud dar de baja',
    loadComponent: () => import('./pages/usuario/solicitud-dar-baja/solicitud-dar-baja.component').then((c) => c.SolicitudDarBajaComponent),
    canActivate: [sessionGuard],
  },
  {
    path: 'aprende-reciclar',
    title: 'Aprende a reciclar',
    loadChildren: () => import('./pages/aprende-reciclar/aprende-reciclar.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.VECINO]}
  },
  {
    path: 'canjear-puntos',
    title: 'Canjear puntos',
    loadChildren: () => import('./pages/canjear-puntos/canjear-puntos.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.VECINO]}
  },
  {
    path: 'carrito',
    title: 'Carrito',
    loadChildren: () => import('./pages/carrito/carrito.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.VECINO]}
  },
  {
    path: 'historial-canjes',
    title: 'Historial de canjes',
    loadChildren: () => import('./pages/historial-canjes/historial-canjes.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.VECINO]}
  },
  {
    path: 'usuario',
    title: 'Usuario',
    loadChildren: () => import('./pages/usuario/usuario.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard],
  },
  {
    path: 'rutas',
    title: 'Rutas',
    loadChildren: () => import('./pages/rutas/rutas.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.ADMINISTRADOR]}
  },
  {
    path: 'premios',
    title: 'Premios',
    loadChildren: () => import('./pages/premios/premios.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.ADMINISTRADOR]}
  },
  {
    path: 'contenido',
    title: 'Contenido',
    loadChildren: () => import('./pages/contenido/contenido.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.ADMINISTRADOR]}
  },
  {
    path: 'planta-reciclaje',
    title: 'Planta de reciclaje',
    loadChildren: () => import('./pages/planta-reciclaje/planta-reciclaje.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.ADMINISTRADOR, RolUsuario.PLANTA]}
  },
  {
    path: 'visualizacion',
    title: 'Visualizacion',
    loadChildren: () => import('./pages/visualizacion/visualizacion.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.ADMINISTRADOR]}
  },
  {
    path: 'notificacion-consulta',
    title: 'Notificaciones y consultas',
    loadChildren: () => import('./pages/notificaciones/notificaciones.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.ADMINISTRADOR]}
  },
  {
    path: 'reportes',
    title: 'Reportes',
    loadChildren: () => import('./pages/reportes/reportes.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.ADMINISTRADOR]}
  },
  {
    path: 'inicio',
    title: 'Inicio',
    loadChildren: () => import('./pages/home/home.routes').then((c) => c.routes),
    canActivate: [checkLogueadoGuard, hasRolGuard],
    data: {rol: [RolUsuario.AUSPICIADOR, RolUsuario.RECOLECTOR]}
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];
