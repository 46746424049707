import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from '@envs/environment.dev';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = environment.MAPBOX_ACCESS_TOKEN;

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
