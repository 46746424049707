import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@pages/auth/services/auth.service';

export const checkLogueadoGuard: CanActivateFn = (route, state) => {
  const authSvc = inject(AuthService);
  const _router = inject(Router);
  
  if (!authSvc.isLogueado()) {
    _router.navigate(['/']);
    return false;
  }
  return true;
};
