import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@pages/auth/services/auth.service';
import { UtilRuta } from '../utils/ruta.util';

export const sessionGuard: CanActivateFn = (route, state) => {
  const authSvc = inject(AuthService);
  const _router = inject(Router);

  if (authSvc.isLogueado()) {
    // _router.navigate(['aprende-reciclar'])
    const ruta = UtilRuta.irRutaPrincipal(authSvc.dataUsuario?.rol.id!);
    _router.navigate(ruta);
    return false;
  }
  return true;
};
